import { LanguageContextType } from "~/context/LanguageContext";

export const CURRENT_OFFER = "30";

export const COUNTRIES_USING_EURO = [
  "at", // Austria
  "be", // Belgium
  "cy", // Cyprus
  "de", // Germany
  "ee", // Estonia
  "es", // Spain
  "fi", // Finland
  "fr", // France
  "gr", // Greece
  "ie", // Ireland
  "it", // Italy
  "lt", // Lithuania
  "lu", // Luxembourg
  "lv", // Latvia
  "mt", // Malta
  "nl", // Netherlands
  "pt", // Portugal
  "si", // Slovenia
  "sk", // Slovakia
];

export const ALGERIA = "dz"; // Use DZD

export const SUPPORTED_LANGUAGES: LanguageContextType["lang"][] = [
  "en", // English
  "fr", // French
  "de", // German
  "ar", // Arabic
];

export const CONTACT_EMAIL = "contact@skillsynergy.net";

export const LINKED_IN_URL = "https://www.linkedin.com/company/skillsynergy";
export const X_URL = "https://x.com/SkillSynergyNet";
export const YOUTUBE_URL = "https://www.youtube.com/@SkillSynergyDotNet";
export const FACEBOOK_URL =
  "https://www.facebook.com/profile.php?id=61559837181591";

export const YOUTUBE_INTRODUCTION_VIDEO_ID = "ens-KF0NxkQ";
export const DZ_YOUTUBE_INTRODUCTION_VIDEO_ID = "HEal7fGuQYs";
